html,
body {
  margin: 0;
  background-color: rgb(10, 10, 10);
  overflow-x: hidden;
  width: 100%;
}

@font-face {
  font-family: "cinzel-medium";
  src: url("./font/Cinzel-Medium.ttf");
}

#ArtPanel {
  background-image: url("./images/Sections/SectionArt.png");
  background-position: center;
  background-size: cover;
}
#GamesPanel {
  background-image: url("./images/Sections/SectionGames.png");
  background-position: center;
  background-size: cover;
}
#CodePanel {
  background-image: url("https://pixelportfolio.onrender.com/static/media/SSDorris.9550cf41.png");
  background-position: center;
  background-size: cover;
}

.svgInstaPos {
  position: absolute;
  left: 0;
}

.svgInsta {
  z-index: 5;
  opacity: 0;
  transition: 0.8s;
}
.svgInsta:hover {
  opacity: 1;
  transition: 0.2s;
}

.svgItch {
  fill: white;
  transition: 0.8s;
}
.svgItch:hover {
  fill: rgb(255, 40, 75);
  transition: 0.2s;
}

.svgLinkedIn {
  fill: white;
  transition: 0.8s;
}
.svgLinkedIn:hover {
  fill: rgb(5, 102, 191);
  transition: 0.2s;
}
